import { createRouter, createWebHistory } from 'vue-router';
import { routeMiddleware } from '@/i18n';
import Home from '@/pages/Home.vue';
import SearchPageLayout from '@/pages/layouts/SearchPageLayout.vue';

export const routeNames = {
    home: 'home',
    tripSearch: 'trip-search',
    tripSearchResult: 'trip-search-result',
    departuresSearch: 'departures-search',
    departuresSearchResult: 'departures-search-result',
    notFound: 'not-found',
    bookingDetails: 'booking-details'
};

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/:locale?',
            components: {
                main: Home,
                appDrawer: SearchPageLayout
            },
            children: [
                {
                    path: '',
                    name: routeNames.home,
                    redirect: { name: routeNames.tripSearch }
                },
                {
                    path: 'search',
                    name: routeNames.tripSearch,
                    components: {
                        default: () => import('@/pages/trips/TripSearchPage.vue'),
                        header: () => import('@/pages/trips/TripSearchPageHeader.vue'),
                        headerAppend: () => import('@/pages/trips/TripSearchPageHeaderAppend.vue'),
                        mapUnderlay: () => import('@/pages/trips/TripSearchMapComponents.vue'),
                        page1: () => import('@/pages/trips/TripSearchDetails.vue')
                    }
                },
                {
                    path: 'departures',
                    name: routeNames.departuresSearch,
                    components: {
                        default: () => import('@/pages/departures/DeparturesSearchPage.vue'),
                        header: () => import('@/pages/departures/DeparturesSearchPageHeader.vue'),
                        headerAppend: () => import('@/pages/departures/DeparturesSearchPageHeaderAppend.vue'),
                        mapUnderlay: () => import('@/pages/departures/DeparturesSearchMapComponents.vue'),
                        page1: () => import('@/pages/departures/DeparturesSearchDetails.vue')
                    }
                },
                {
                    path: 'bookings/:id',
                    name: routeNames.bookingDetails,
                    components: {
                        default: () => import('@/pages/bookings/BookingPage.vue'),
                        page1: () => import('@/pages/bookings/BookingDetailsPage.vue')
                    },
                    props: {
                        default: false,
                        page1: true
                    }
                }
            ]
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: { name: routeNames.home }
        }
    ]
});

router.beforeEach(routeMiddleware);

export default router;
