import { computed, nextTick } from 'vue';
import { SearchMode } from '@/features/common/stores';
import { useRouter } from 'vue-router';
import { routeNames } from '@/router';
import { useDeparturesSearchStore } from '@/features/departures/stores';
import { useTripSearchStore } from '@/features/trips/stores';
import { PlaceType } from '@/types/webapi';
import { i18nRoute } from '@/i18n';

export function useSearchModeToggle() {
    const router = useRouter();
    const { currentRoute } = router;
    const tripStore = useTripSearchStore();
    const departuresStore = useDeparturesSearchStore();
    const disabled = computed(() => tripStore.isLoading || departuresStore.isLoading);

    const mode = computed({
        get() {
            return currentRoute.value.name === routeNames.tripSearch
                ? SearchMode.Trip
                : currentRoute.value.name === routeNames.departuresSearch
                  ? SearchMode.Departures
                  : SearchMode.None;
        },
        set(value) {
            setMode(value);
        }
    });

    function setMode(mode: SearchMode) {
        if (mode == SearchMode.Trip) {
            // copy over quay as from value, if set
            const { quay } = departuresStore.searchRequest;

            // reset store
            departuresStore.init();

            nextTick(async () => {
                // reset store
                tripStore.init();

                // navigate
                await router.push(
                    i18nRoute({
                        name: routeNames.tripSearch,
                        query: {
                            fromId: quay ? quay.id : undefined
                        }
                    })
                );
            });
        } else if (mode == SearchMode.Departures) {
            // copy over from as quay value, if set and is quay
            const { from } = tripStore.searchRequest;

            // reset store
            tripStore.init();

            nextTick(async () => {
                // reset store
                departuresStore.init();

                // navigate
                await router.push(
                    i18nRoute({
                        name: routeNames.departuresSearch,
                        query: {
                            fromId: from?.type === PlaceType.TransitStation ? from.id : undefined
                        }
                    })
                );
            });
        }
    }

    return {
        mode,
        disabled
    };
}
