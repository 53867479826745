import { nextTick, watch, WatchHandle } from 'vue';
import { useBookingStore } from '../stores';
import { useMainStore } from '@/features/common/stores';
import { useTripSearchStore } from '@/features/trips/stores';
import { useDeparturesSearchStore } from '@/features/departures/stores';
import { isEmpty } from 'lodash-es';
import { isAfter, isDate, isEqual } from 'date-fns';
import { DepartureDto, LegDto, NotificationMessageType } from '@/types/webapi';
import { useBookingMessages } from './useBookingMessages';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/features/authentication';

export function useBookingFeature() {
    const mainStore = useMainStore();
    const userStore = useUserStore();
    const bookingStore = useBookingStore();
    const tripSearchStore = useTripSearchStore();
    const departureSearchStore = useDeparturesSearchStore();
    const { stopSignalOrder, maxBookingExceeded, bookings, selectedBooking } = storeToRefs(bookingStore);
    const { generateBookingExistsDescriptionText } = useBookingMessages();

    function updateNotificationMessage(legOrDeparture: LegDto | DepartureDto) {
        const bookingNotificationMessage = legOrDeparture.notificationMessages?.find(
            x => x.type === NotificationMessageType.BookingArrangement
        );

        if (!bookingNotificationMessage) return;

        const leg = legOrDeparture as LegDto;
        const departure = legOrDeparture as DepartureDto;
        const fromQuay = leg.from?.quay?.name || departure.quay?.name;
        const transportMode = leg.transitInfo?.transportMode || departure.transportMode;

        // store original message
        (bookingNotificationMessage as any).originalText = bookingNotificationMessage.text;

        bookingNotificationMessage.text = generateBookingExistsDescriptionText({ fromQuay, transportMode });
        bookingNotificationMessage.type = NotificationMessageType.BookingConfirmation;
    }

    function restoreNotificationMessage(legOrDeparture: LegDto | DepartureDto) {
        const notificationMessage = legOrDeparture.notificationMessages?.find(x => x.type === NotificationMessageType.BookingConfirmation);
        if (!notificationMessage || !('originalText' in notificationMessage)) return;

        notificationMessage.text = notificationMessage.originalText as string;
        notificationMessage.type = NotificationMessageType.BookingArrangement;

        delete notificationMessage.originalText;
    }

    async function loadBookingData(legOrDeparture: LegDto | DepartureDto) {
        if (!legOrDeparture.bookingMetadata) return;

        if (userStore.isLoggedIn) {
            // load user bookings
            await bookingStore.loadBookings();
            await nextTick();

            // check if user has valid booking, and update notification messages as necessary
            if (bookings.value.length > 0) {
                const { activeDate, serviceJourneyRef, stopSequence } = legOrDeparture.bookingMetadata;

                // match by activeDate + serviceJourneyRef + stopSequence
                if (
                    bookings.value.find(
                        x =>
                            isDate(activeDate) &&
                            isEqual(x.activeDate, activeDate) &&
                            x.serviceJourneyRef === serviceJourneyRef &&
                            x.stopSequence === stopSequence
                    )
                ) {
                    return updateNotificationMessage(legOrDeparture);
                }
            }

            restoreNotificationMessage(legOrDeparture);
        }

        const { bookingLimitDateTime } = legOrDeparture.bookingMetadata;
        if (!isDate(bookingLimitDateTime) || isAfter(bookingLimitDateTime, mainStore.currentTime)) return;

        await bookingStore.loadStopSignalOrder();
        await nextTick();

        // update notification message if stopSignalOrder exists
        if (!stopSignalOrder.value) return;

        updateNotificationMessage(legOrDeparture);
    }

    function reset() {
        selectedBooking.value = undefined;
        stopSignalOrder.value = undefined;
        maxBookingExceeded.value = false;
    }

    let tripPatternWatcher: WatchHandle | undefined;
    let departureWatcher: WatchHandle | undefined;

    function startWatchers() {
        tripPatternWatcher = watch(
            () => tripSearchStore.selectedTripPattern,
            async (value, oldValue) => {
                if (isEmpty(value) || value.id === oldValue?.id) return;

                reset();

                const bookingLeg = value.legs?.find(x => x.bookingMetadata?.onlineBookingIsAvailable);
                if (!bookingLeg) return;

                await loadBookingData(bookingLeg);
            },
            { immediate: true }
        );

        departureWatcher = watch(
            () => departureSearchStore.selectedDeparture,
            async (value, oldValue) => {
                if (isEmpty(value?.bookingMetadata) || value.id === oldValue?.id) return;

                reset();

                await loadBookingData(value);
            },
            { immediate: true }
        );
    }

    function stopWatchers() {
        tripPatternWatcher?.();
        departureWatcher?.();
    }

    watch(
        () => mainStore.config.bookingConfig?.enabled,
        value => {
            if (value) {
                startWatchers();
            } else {
                stopWatchers();
            }
        },
        {
            immediate: true
        }
    );
}
