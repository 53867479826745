import { syncRef, useSessionStorage, watchDebounced } from '@vueuse/core';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useMainStore } from '../stores';
import { storeToRefs } from 'pinia';
import { DevModeConfiguration } from '../models';
import { merge } from 'lodash-es';

const MIN_THRESHOLD = 10;

export function useDevModeActivator() {
    const mainStore = useMainStore();
    const { isDevMode, devModeConfiguration, config, defaultConfig } = storeToRefs(mainStore);

    const sessionStorage = useSessionStorage('travelplanner__devMode', { enabled: false });
    const devModeConfigurationSessionStorage = useSessionStorage<DevModeConfiguration>('travelplanner__devModeConfig', {
        enableAiSearch: false,
        enableBooking: !!defaultConfig.value?.bookingConfig?.enabled,
        enableDebugMode: !!defaultConfig.value?.isDebugModeEnabled,
        enturEndpointType: 0,
        ngEndpointType: 0
    });

    const router = useRouter();
    const { currentRoute } = router;
    const modeChangeCounter = ref(0);
    const dialogIsVisible = ref(false);
    const showDevModeNotification = ref(false);

    function init() {
        syncRef(sessionStorage, isDevMode, {
            transform: {
                rtl: right => ({ enabled: !!right }),
                ltr: left => !!left.enabled
            },
            immediate: true
        });

        syncRef(devModeConfigurationSessionStorage, devModeConfiguration, {
            transform: {
                rtl: right => (right ? { ...right } : {}),
                ltr: left => (left ? { ...left } : {})
            },
            immediate: true
        });

        syncRef(devModeConfigurationSessionStorage, config, {
            transform: {
                rtl: right => ({
                    ...devModeConfigurationSessionStorage.value,
                    enableBooking: !!right.bookingConfig?.enabled,
                    enableDebugMode: !!right.isDebugModeEnabled
                }),
                ltr: left =>
                    merge({}, config.value, { bookingConfig: { enabled: left.enableBooking }, isDebugModeEnabled: left.enableDebugMode })
            },
            immediate: true
        });

        // set initial value
        if (config.value.devModeEnabled) {
            isDevMode.value = true;
        }

        // start activator trigger if devMode isn't already activated
        if (!isDevMode.value) {
            const unwatchRoute = watch(currentRoute, (value, oldValue) => {
                if (value.name === oldValue.name) return;

                modeChangeCounter.value += 1;
            });

            const unwatchDebounced = watchDebounced(modeChangeCounter, () => (modeChangeCounter.value = 0), {
                debounce: 10000,
                maxWait: 15000
            });

            const unwatchCounter = watch(modeChangeCounter, value => {
                if (value < MIN_THRESHOLD) return;

                // once enabled, we no longer need this
                unwatchCounter();
                unwatchRoute();
                unwatchDebounced();

                isDevMode.value = true;
                showDevModeNotification.value = true;
            });
        }
    }

    function resetConfig() {
        devModeConfiguration.value = {
            enableAiSearch: false,
            enableBooking: !!defaultConfig.value?.bookingConfig?.enabled,
            enableDebugMode: !!defaultConfig.value?.isDebugModeEnabled,
            enturEndpointType: 0,
            ngEndpointType: 0
        };
        dialogIsVisible.value = false;
    }

    return { dialogIsVisible, showDevModeNotification, init, resetConfig };
}
